<template>
  <div class="tc-local_backup">
    <div class="form">
      <el-form ref="form" :model="form" label-width="150px " :rules="rules" size="mini">
        <el-form-item label="请选择量表文件：">
          <el-upload
            class="upload-demo"
            action="/file_upload"
            :before-upload="beforeUpload"
            :limit="1"
            :on-success="measureFile"
            :data="fileData"
            :file-list="form.fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传量表文件，且不超过5000kb</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="请选择量表类型：" prop="classifyId">
          <!-- <span>请先选择部门</span> -->
          <el-select v-model="form.classifyId">
            <el-option
              :label="item.classify_name"
              :value="item.id"
              v-for="(item, key) in classifyList"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="自定义量表名称：" prop="customName">
          <el-input v-model="form.customName"></el-input>
        </el-form-item>
        <el-form-item label="自定义答题说明：">
          <el-input type="textarea" :rows="2" v-model="form.customDesc"></el-input>
        </el-form-item>
        <el-form-item label="图表样式">
          <el-select v-model="form.theme" placeholder="请选择">
            
            <el-option label="单数据-普通绿色仪表盘" value="1"></el-option>
            <el-option label="多数据-渐变紫色折线图" value="2"></el-option>
            <el-option label="多数据-渐变黄色网状圆盘" value="3"></el-option>
            <el-option label="单数据-炫酷紫色仪表盘" value="4"></el-option>
            <el-option label="多数据-五彩斑斓不规则圆盘-1" value="5"></el-option>
            <el-option label="多数据-五彩斑斓不规则圆盘-2" value="6"></el-option>
            <el-option label="单数据-渐变仪表盘" value="7"></el-option>
            <el-option label="多数据-柱状和折线的结合" value="9"></el-option>
            <el-option label="多数据-淡红峰形柱状图" value="10"></el-option>
            <el-option label="多数据-炫酷圆环扇形图" value="11"></el-option>
            <el-option label="单数据-SDS专用" value="8"></el-option>
            <el-option label="单数据-SAS专用" :value="88"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-left:200px">
      <img src="@/assets/images/them-1.png" alt v-if="form.theme ==1" />
      <img src="@/assets/images/them-2.png" alt v-if="form.theme ==2" />
      <img src="@/assets/images/them-3.png" alt v-if="form.theme ==3" />
      <img src="@/assets/images/them-4.png" alt v-if="form.theme ==4" />
      <img src="@/assets/images/them-5.png" alt v-if="form.theme ==5" />
      <img src="@/assets/images/them-6.png" alt v-if="form.theme ==6" />
      <img src="@/assets/images/them-7.png" alt v-if="form.theme ==7" />
      <img src="@/assets/images/them-8.png" alt v-if="form.theme ==8" />
      <img src="@/assets/images/them-9.png" alt v-if="form.theme ==9" />
      <img src="@/assets/images/them-10.png" alt v-if="form.theme ==10" />
      <img src="@/assets/images/them-11.png" alt v-if="form.theme ==11" />
    </div>
    <div class="confirm-btn" @click="onSubmit">
      <img src="@/assets/images/addbtn.png" alt />
    </div>
  </div>
</template>
<script>
import { getMeasureClassifyList, addMeasure } from "@/api/evaluate.js";
export default {
  inject: ["reload"],
  data() {
    return {
      rules: {
        classifyId: [
          { required: true, message: "请选择量表类型", trigger: "change" }
        ]
        // customName: [
        //   { required: true, message: "请输入自定义量表名称", trigger: "blur" }
        // ]
      },
      fileData: {
        type: 4,
        attribution_id: 1 // 当前 用户的id
      },
      classifyList: [],
      form: {
        measurePath: "",
        classifyId: "",
        customName: "",
        customDesc: "",
        theme: "9",
        fileList: []
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 刷新
    refresh() {
      this.reload();
    },
    init() {
      let param = {
        page: 1,
        size: 999
      };
      getMeasureClassifyList(param).then(async res => {
        //console.log("getMeasureClassifyList-res", res);
        if (res.code === 400200) {
          this.classifyList = res.data.data;
        }
      });
    },
    onSubmit() {
      //console.log("submit!", this.form);
      if (this.form.measurePath == "") {
        this.$message.error("请上传文件");
        return;
      } else {
        this.$refs["form"].validate(valid => {
          if (valid) {
            addMeasure(this.form).then(async res => {
              //console.log("res", res);
              if (res.code === 400200) {
                this.$message.success(res.msg);
                this.reload();
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            return;
          }
        });
      }
    },
    beforeUpload(file) {
      const isJson = file.type === "application/json";
      if (!isJson) {
        this.$message.error("上传文件只能是JSON格式!");
      }
      return isJson;
    },
    measureFile(response, file, fileList) {
      if (response.code === 400200) {
        this.form.measurePath = response.data.url;
        // this.$router.push("/measure");
      } else {
        this.$message.error("文件上传失败!");
      }
    }
  }
};
</script>
<style lang="less">
.tc-local_backup {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 120px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 120px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  .form {
    margin-top: 20px;
    margin-left: 50px;
    width: 500px;
    .el-input__inner {
      width: 200px;
      padding-right: 20px;
    }
  }
  .confirm-btn {
    margin-top: 20px;
    margin-left: 300px;
    width: 100px;
    img {
      width: 100%;
    }
  }
}
</style>